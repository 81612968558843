
<script>
export default {
  props: {
    imgSrc: {
      type: String,
    }
  },
  data() {
    return {
    };
  },
  methods: {
    selectTab(index) {
    }
  }
};
</script>


<template>
    <div class="atlas">
       <img :src="imgSrc" style="height: 100%;width: 100%"/>
    </div>
</template>

<style scoped lang="scss">
.atlas{
  width: 100%;
  height: calc(100vh - 260px); /* 根据需要调整高度 */;
}
</style>

<script>
import { render } from 'nprogress';
export default {
  props: {
    name: {
      type: String,
      default: "新闻板块"
    },
    cardType: {
      type: String,
      default: ""

    },
    width: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      editType: false,
      inputValue:"",
      showEditMap:true,
    };
  },
  methods: {
    selectTab(index) {
    },
    eidtBtnDisplay(e) {
      // const index = e.target.getAttribute('data-index');

      this.editType = true
    },
    handleMouseLeave(){
      this.editType = false
    },



    /**
     * @Author: YangXin
     * @Description: 判断边框样式添加
     * @Date: 2024-08-03 14:26:28
     * @return {*}
     */
    cardTypefunc() {
      switch (this.cardType) {
        case "card":
          return "card"
        case "bezel_less":
          return "bezel_less"
        default:
          return "card"
      }
    },
    handleInput(value) {
      // 更新本地数据以模拟 v-model 的双向绑定
      this.inputValue = value;
    },


    editNameFunc(){
      this.$prompt('请输入名称', '修改', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          inputValue:this.currentDepartment[this.$attrs.dataCardIndex].name
        }).then(({ value }) => {
          this.currentDepartment[this.$attrs.dataCardIndex].name = value
          this.$message({
            type: 'success',
            message: "添加成功"
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '取消输入'
          });
        });

    },

    editDataSource:function(){

      this.$emit('showEditMapData');

    },


    delAll:function(){
      document.body.style.overflow = '';
      this.$confirm('检测到要删除全部？', '确认信息', {

          distinguishCancelAndClose: true,
          confirmButtonText: '删除',
          cancelButtonText: '放弃删除'
        })
          .then(() => {
            console.log(this)
document.body.style.overflow = '';

            this.currentDepartment.splice(this.$attrs.dataCardIndex,1)
            console.log(this.currentDepartment)
            this.$message({
              type: 'info',
              message: '删除成功'
            });

          })
          .catch(action => {
            this.$message({
              type: 'info',
              message: action === 'cancel'
                ? '放弃保存并离开页面'
                : '停留在当前页面'
            })
          });

    }
  },
  computed: {
      currentDepartment() {
      return this.$store.getters["schema/schema"].value

    },
    bigPictureEdit(){
      return this.$store.getters["bigPictureEditType"]
    }
  },
};
</script>
<!-- @mouseover="mouseoverFunc"  -->
<template>


    <div :class="cardTypefunc()" @mouseenter="eidtBtnDisplay" @mouseleave="handleMouseLeave">

      <div>· {{ name }} ·</div>
      <slot @mouseenter="handleMouseLeave"></slot>

      <div class="topMenu" v-show="editType" v-if="bigPictureEdit">
      <el-dropdown trigger="click">
        <span class="el-dropdown-link">
          <el-button>编辑</el-button>
        </span>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item  @click.native="editNameFunc" >名称</el-dropdown-item>
          <!-- <el-dropdown-item  @click.native="editDataSource" v-if="this.showEditMap" >编辑</el-dropdown-item> -->
          <el-dropdown-item   @click.native="delAll">删除</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
    </div>


</template>

<style scoped lang="scss">
.topMenu {
  top: 0;
  left: 20px;
  position: absolute;
  z-index: 1;
}

.card {
  width: max-content;
  min-height: calc(8vh - 52px - 72px);
  background: rgba(11, 70, 127, 0.34);
  border-radius: 42px;
  border: 1px solid #2E6099;
  backdrop-filter: blur(4px);

  background-image: url("~@/assets/images/data/zs.png"),
    url("~@/assets/images/data/ys.png"),
    url("~@/assets/images/data/zx.png"),
    url("~@/assets/images/data/yx.png");
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top left, top right, bottom left, bottom right;
  z-index: 1;
  padding: 2px 2px 3px 2px; //设置padding给div往里挤

  margin-right: 58px;

  &:first-child {
    margin-left: 48px;
  }

  display: flex;

  &>div:first-child {
    height: calc(100vh - 50px - 72px);
    width: 30px;
    background-image: url("~@/assets/images/data/window-left.png");
    background-repeat: no-repeat;
    background-position: center center;
    /* 水平垂直居中 */

    margin-left: -27px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 29px;
    text-align: center;
    margin-right: 32px;

  }

  &>div:not(div:first-child) {
    //background-color: #06a37e;
    width: 100%;
    min-width: 470px;
    margin: 24px 24px 24px 0;


  }


}

// 添加100%框架样式
.card2 {
  width: max-content;
  min-height: calc(100vh - 52px - 72px);
  background: rgba(11, 70, 127, 0.34);
  border-radius: 42px;
  border: 1px solid #2E6099;
  backdrop-filter: blur(4px);

  background-image: url("~@/assets/images/data/zs.png"),
    url("~@/assets/images/data/ys.png"),
    url("~@/assets/images/data/zx.png"),
    url("~@/assets/images/data/yx.png");
  background-repeat: no-repeat, no-repeat, no-repeat, no-repeat;
  background-position: top left, top right, bottom left, bottom right;
  z-index: 1;
  padding: 2px 2px 3px 2px; //设置padding给div往里挤

  margin-right: 58px;

  &:first-child {
    margin-left: 48px;
  }

  display: flex;

  &>div:first-child {
    height: calc(100vh - 50px - 72px);
    width: 30px;
    background-image: url("~@/assets/images/data/window-left.png");
    background-repeat: no-repeat;
    background-position: center center;
    /* 水平垂直居中 */

    margin-left: -27px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 29px;
    text-align: center;
    margin-right: 32px;

  }

  &>div:not(div:first-child) {
    //background-color: #06a37e;
    width: 100%;
    min-width: 470px;
    margin: 24px 24px 24px 0;


  }


}

.bezel_less {
  width: max-content;
  min-height: calc(100vh - 52px - 72px);
  // background: rgba(11, 70, 127, 0.34);
  // border-radius: 42px;
  // border: 1px solid #2E6099;
  border: 2px dashed #a0e7cd;
  // backdrop-filter: blur(4px);

  padding: 2px 2px 3px 2px; //设置padding给div往里挤

  margin-right: 58px;

  &:first-child {
    margin-left: 48px;
  }

  display: flex;

  &>div:first-child {
    height: calc(100vh - 50px - 72px);
    width: 30px;
    // background-image: url("~@/assets/images/data/window-left.png");
    // background-repeat: no-repeat;
    // background-position: center center;
    /* 水平垂直居中 */

    margin-left: -27px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-family: PingFangSC, PingFang SC;
    font-weight: 500;
    font-size: 20px;
    color: #FFFFFF;
    line-height: 29px;
    text-align: center;
    margin-right: 32px;

  }

  &>div:not(div:first-child) {
    //background-color: #06a37e;
    width: 100%;
    min-width: 40px;
    margin: 24px 24px 24px 0;
  }
}
</style>

<template>
  <fragment class="engineMain">

    <Vuedraggable :list="data" :group="engineGroup" :sort="bigPictureEdit" :disabled="!bigPictureEdit" style="display: flex;" :axis="'x'" handle=".drag-handle">
      <data-card :name="card['name']" ref="cardParentRef" :cardType="card['type']" v-for="(card, index) in data"
        :key="index" :style="'cursor: move;'+card?.dataCardStyle" :dataCardIndex="index" class="drag-handle">
        <Vuedraggable :list="card['children']" :group="engineChildrenGroup" :disabled="!bigPictureEdit"
          style="display: flex;justify-content: center;height: 95%;cursor: move;"  :axis="'x'"   handle=".drag-handle" :sort="bigPictureEdit" @add="childrenAddItem">
          <div v-for="(block, cardChildIndex) in card['children']" :key="cardChildIndex">
            <data-block :name="block['name']" :width="block['width']" v-if="block['type'] === 'block'"
              :cardIndexId="{ prentId: index, indexBlock: cardChildIndex }" style="cursor: move;" class="drag-handle">
              <Vuedraggable :list="block['children']" :group="blockChildrenGroup" :cardChildIndex="cardChildIndex"
                :dataCardIndex="index" style="display: flex;justify-content: center;height: 96%;" :sort="false" :axis="'x'"
                ref="ref">
                <div v-for="(element, blackChildrenIndex) in block['children']" :key="blackChildrenIndex"
                  @mouseleave="handleMouseLeave"
                  @mouseenter.stop="eidtBtnDisplay(index,element )" style="width: 100%;cursor: move;"   class="drag-handle">
                  <div class="childrenTopMenu" v-show="element === topMenuType" v-if="bigPictureEdit">
                    <el-dropdown trigger="click">
                      <span class="el-dropdown-link">
                        <el-button>编辑</el-button>
                      </span>
                      <el-dropdown-menu slot="dropdown">
                        <el-dropdown-item  v-if="element['type'] === 'web-view' || element['type'] === 'JumpImg'"
                          @click.native="editUrlIndex(block, blackChildrenIndex)">编辑url</el-dropdown-item>

                          <el-dropdown-item  v-if="element['type'] === 'img'"
                          @click.native="imgEditor({ dataCardIndex: index, cardTwoIndex: cardChildIndex,type:true}, blackChildrenIndex)">图片编辑器</el-dropdown-item>
                                                    <el-dropdown-item  v-if="element['type'] === 'videoItem'"
                          @click.native="imgEditor({ dataCardIndex: index, cardTwoIndex: cardChildIndex,type:true }, blackChildrenIndex)">视频编辑器</el-dropdown-item>
                          <el-dropdown-item  v-if="element['type'] === 'pdf-viewer'"
                          @click.native="imgEditor({ dataCardIndex: index, cardTwoIndex: cardChildIndex,type:true }, blackChildrenIndex)">pdf编辑器</el-dropdown-item>
                        <el-dropdown-item  v-if="element['type'] === 'graphic-list'"
                          @click.native="OnlineEditor({ dataCardIndex: index, cardTwoIndex: cardChildIndex ,type:true}, blackChildrenIndex)">在线编辑器</el-dropdown-item>
                        <el-dropdown-item @click.native="delBlockItemFunc(block, blackChildrenIndex)">删除</el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                  </div>
                  <web-view v-if="element['type'] === 'web-view'" :src="element['src']"
                    :height="element['height'] || '1058px'" :width="element['width']" />
                    <div  v-if="element['type'] === 'img'" style="height: 100%;width: 100%;">
                      <imgList :imgList="element['imgList']" :cardData="block"></imgList>
                    </div>
                  <map-marker-cluster :data="getDataMapByKey(element['dataSources'])"
                    v-if="element['type'] === 'map-marker-cluster'" />
                    <img v-if="element['type']==='JumpImg'"  :src="element['src']"  :height="element['height']||1058" :width="element['width']"
                         @click="gotoUrl(element['clickUrl'])"  :alt="element['name']||block['name']"/>
                  <videojs v-if="element['type'] === 'videoItem'" :vodeoList="element['vodeoList']"></videojs>
                  <graphic-list :data="getDataMapByKey(element['dataSources'])"
                                :mapping="element['mapping']"
                    :style="element['style']" @click="element?.clickEvent"
                    v-if="element['type'] === 'graphic-list'"></graphic-list>
                  <articleDetails v-if="element['type'] === 'articleDetails'"></articleDetails>
                  <pdf-viewer :srcList="element['srcList']"  :title="element['title']"
                    v-if="element['type'] === 'pdf-viewer'" :carWidth="block" :style="element['style']"
                    @click="element?.clickEvent"></pdf-viewer>
                </div>
              </Vuedraggable>
            </data-block>
            <div v-if="block['type'] === 'div'" :style="{ 'width': block['width'] }">
              <div v-for="(element, index) in block['children']" :key="index">
                <web-view v-if="element['type'] === 'web-view'" :src="element['src']"
                  :height="element['height'] || '1058px'" :width="element['width']" />
                <map-marker-cluster :data="getDataMapByKey(element['dataSources'])"
                  v-if="element['type'] === 'map-marker-cluster'" />
                <graphic-list :data="getDataMapByKey(element['dataSources'])" :mapping="element['mapping']"
                  v-if="element['type'] === 'graphic-list'" :style="element['style']"
                  @click="element['clickEvent']"></graphic-list>
                <pdf-viewer :srcList="element['srcList']" :title="element['title']"
                  v-if="element['type'] === 'pdf-viewer'" :style="element['style']"
                  @click="element['clickEvent']"></pdf-viewer>
              </div>
            </div>
          </div>
        </Vuedraggable>
      </data-card>
    </Vuedraggable>
<!--    <data-card name="其他">-->
<!--      <data-block name="外链平台">-->
<!--        <links />-->
<!--      </data-block>-->
<!--    </data-card>-->
  </fragment>
</template>

<script>


import DataCard from "./components/card"
import DataBlockTabs from "./components/block/block_tabs"
import DataBlock from "./components/block/block"
import Search from "./components/search/search"
import GraphicList from "@/views/data/components/graphicList/graphicList";
import DataVideos from "@/views/data/components/videos/videos";
import WebView from "@/views/data/components/webView/webView"
import Atlas from "@/views/data/components/atlas/atlas"
import MarkerCluster from "./components/maps/markerCluster"
import pdfViewer from './components/pdf-viewer/listPdf'
import links from './components/links/index'
import Vuedraggable from 'vuedraggable' // 添加拖拽组件
import articleDetails from "./components/articleDetails/articleDetails"
import videojs from "./components/videos/videos.vue"
import imgList from "./components/imgList/imgList.vue"

import { apiMixin } from './mixin/api.mixin';
import { mapGetters, mapState } from "vuex";
import store from "@/store";
import { gsap } from "gsap-trial";



export default {
  props: {
    data: {
      type: Array,
      default: () => []
    },
    dataCardStyle: {
      type: String,
      default: () => ""
    }
  },
  data() {
    return {
      currentTime: new Date().toLocaleString(),
      pdfUrls: ["/mock/yzt/内蒙古自治区种业布局图放大版.pdf"],
      pdfCurrentDepartment: {},
      pdfCurrentTitle: "",
      engineGroup: { name: 'dataCardItem', pull: false, put: true },
      engineChildrenGroup: { name: 'dataCardItemChildren', pull: false, put: false },
      blockChildrenGroup: { name: 'blockChildren', pull: false, put: false },
      active: "",
      topMenuType: "",
      inputData: "",
      urlSelectId: "",
      num:0,

      options: [
        {
          value: '1',
          label: '内嵌网页'
        }, {
          value: '2',
          label: '跳转网页'
        },
      ]
    }
  },
  mixins: [],
  components: {
    links,
    pdfViewer,
    "map-marker-cluster": MarkerCluster,
    "graphic-list": GraphicList,
    DataVideos,
    WebView,
    Atlas,
    DataCard, Search,
    DataBlock,
    DataBlockTabs,
    Vuedraggable,
    articleDetails,
    videojs,
    imgList
  },
  computed: {
    ...mapGetters({
      getDataMapByKey: 'schema/dataMapByKey',

      bigPictureEdit: 'bigPictureEditType'
    })
  },
  mounted() {
    let that = this;
    this.$watch('data', (newVal, oldVal) => {
      setTimeout(() => {
        // console.log(this.data)
        // this.setData()

      }, 2000);
    }, { immediate: true, deep: true });
  },
  methods: {
    gotoUrl(url) {
      var newWindow = window.open(url, '_blank');
      newWindow.onload = function() {
          newWindow.location = url; // 确保使用HTTPS URL
      };
    },
    pdfClick(e) {
      this.pdfUrls = []
      this.pdfUrls = ["/mock/yzt/" + e.fileContent];
      this.pdfCurrentDepartment = e.deptName;
      this.pdfCurrentTitle = e.title;
    },

    /**
     * @Author: YangXin
     * @Description: 通过获取vue虚拟dom来修改data数据
     * @Date: 2024-08-05 10:27:39
     * @param {*} e
     * @return {*}
     */
    childrenAddItem(e) {
      let typeObject = {
        name: "",
        children: "",
        type: "block",
        width: "2000px",
        dragName: ''
      }
      const scrollContainer = this.$parent.$refs["main-body"];
      // 方式1 操作vue 虚拟dom

      this.data[e.to.__vue__.$parent.$attrs.dataCardIndex]["dataCardStyle"] = ""
      this.data[e.to.__vue__.$parent.$attrs.dataCardIndex]['type'] = "card"
      if (e.item.innerText == "100%框架") {
        typeObject.dragName = "100%框架"
        this.data[e.to.__vue__.$parent.$attrs.dataCardIndex].children.splice(e.newDraggableIndex , 0, typeObject)
      }
      if (e.item.innerText == "固定框架") {
        typeObject.dragName = "固定框架"
        typeObject.width = "400px"

        this.data[e.to.__vue__.$parent.$attrs.dataCardIndex].children.splice(e.newDraggableIndex, 0, typeObject)
      }
      // this.$parent.contentChanged();

      // 方式2 操作真实dom
      //  let parentNode = e.to.parentNode
      //  parentNode.style = ""
      //  parentNode.setAttribute("class","card");
    },


    /**
     * @Author: YangXin
     * @Description: 创建虚拟dom
     * @Date: 2024-08-12 09:25:47
     * @return {*}
     */

    render(h) {
      return h('div', null, [
        // h('div', {
        //   style: "height:30px;width:100%;margin-bottom:5px;",
        // }, [
        //   h('span', {
        //     style: "display: inline-block;width:23%;",
        //   }, "修改id："),
        //   h('el-input', {
        //     style: "height:100%;width:70%;",
        //     props: {
        //       value: this.inputData
        //     },
        //     on: {
        //       input: (value) => { // 使用传统函数表达式来确保this指向Vue实例
        //         this.inputData = value;
        //       }
        //     }
        //   }),

        // ]),
        h('div', {
          style: "height:30px;width:100%;margin-bottom:5px;",
        }, [
          h('span', {
            style: "display: inline-block;width:23%;",
          }, "修改url地址："),
          h('el-input', {
            ref: 'urlInputRef',
            style: "height:100%;width:70%;",
            props: {
              value: this.urlInput
            },
            on: {
              input: (value) => { // 使用传统函数表达式来确保this指向Vue实例
                this.urlInput = value;
                this.$refs.urlInputRef.value = value
              }
            }
          }),

        ]),
        // h('div', {
        //   style: "display: inline-block;width:22%;height:30px;width:100%;",
        // }, [
        //   h('span', {
        //     style: "display: inline-block;width:23%;",
        //   }, "模块分类："),
        //   h('el-select', {
        //     ref: 'sendMsg',
        //     style: "height:100%;width:70%;",
        //     props: {
        //       value: this.urlSelectId
        //     },

        //     on: {

        //       change: (event) => {
        //         this.urlSelectId = event
        //         this.$refs['sendMsg'].value = event;

        //       }
        //     }
        //   }, [
        //     this.options.map(option =>
        //       h('el-option', {
        //         key: option.value,
        //         props: {
        //           label: option.label,
        //           value: option.value
        //         },
        //       })
        //     ),
        //   ]),

        // ]),
      ])


    },

    /**
     * @Author: YangXin
     * @Description: 编辑url弹窗
     * @Date: 2024-08-10 16:39:41
     * @return {*}
     */
    editUrlIndex(item, index) {

      const h = this.$createElement;
      this.$msgbox({
        title: 'url修改',
        message: this.render(h),
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      }).then(action => {

        if(item.children[index].type == "web-view"){
            item.children[index].src = this.urlInput
        }
        if(item.children[index].type == "JumpImg"){
            item.children[index].clickUrl = this.urlInput
        }
        this.$message({
          type: 'info',
          message: "修改成功"
        });
      });
    },

    /**
     * @Author: YangXin
     * @Description: 在线编辑器弹窗
     * @Date: 2024-08-10 19:57:34
     * @return {*}
     */
    OnlineEditor(item) {
      this.$parent.$refs.editor.cardItemObject = item
      this.$parent.$refs.editor.imgTextEditType = true
    },

    imgEditor(item){
      this.$parent.$refs.editor.cardItemObject = item
      this.$parent.$refs.editor.imagesEidtDialogType = true
    },




    /**
     * @Author: 添加孙子删除
     * @Description:
     * @Date: 2024-08-10 16:24:32
     * @return {*}
     */
    delBlockItemFunc(item) {
      this.$confirm('此操作将移除框架中的模块, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          item.children = ""
          this.$message({
            type: 'success',
            message: '删除成功!'
          });
        }).catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          });
        });
    },


    /**
     * @Author: YangXin
     * @Description: 移出事件
     * @Date: 2024-08-10 15:38:04
     * @return {*}
     */

    handleMouseLeave: function () {

      this.topMenuType = ""
    },

    /**
     * @Author: YangXin
     * @Description:  移入事件
     * @Date: 2024-08-10 15:38:46
     * @param {*} index
     * @param {*} childIndex
     * @param {*} childrenIndex
     * @return {*}
     */
    eidtBtnDisplay(index, item) {
      this.$refs.cardParentRef[index].editType = false
      this.topMenuType = item
    }
  }
}


</script>



<style scoped lang="scss">
.childrenTopMenu {
  position: absolute;

  z-index: 99;

}

  // .engineMain{
  //   overflow-x: scroll;
  // }

</style>

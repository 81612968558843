
<script>
export default {
  props: {
  },
  data() {
    return {
      input1: '',
      input2: '',
      input3: '',
      select: '1'
    };
  },
  methods: {
    selectTab(index) {
    }
  }
};
</script>

<template>
<div style="display: flex;align-items: center;justify-content: center;">
  <div class="search">
    <div style="text-align: center;margin-bottom: 20px"> <h1>农牧大数据智能搜索</h1></div>
    <div style="margin-top: 15px;border: #06a37e 1px solid">
      <el-input placeholder="请输入内容" v-model="input3" class="input-with-select">
        <el-select v-model="select" slot="prepend" placeholder="请输入搜索内容">
          <el-option label="百度" value="1"></el-option>
          <el-option label="农牧数据" value="2"></el-option>
          <el-option label="AI问答" value="3"></el-option>
        </el-select>
        <el-button slot="append" icon="el-icon-search">搜索</el-button>
      </el-input>
    </div>

  </div>
</div>
</template>

<style scoped lang="scss">
.search{
  width: 600px;
  margin-top: -200px;
}

.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
</style>

<!--
 * @Description:
 * @Author: YangXin
 * @Date: 2024-08-19 08:43:52
 * @listEditTime: Do not edit
-->
<template>
  <div class="imgMain" >
    <div v-for="(item,index) in imgList" :key="index" class="bigImgStyle" >
      <el-image
        style="height:100%;width:100%;position: relative;"
         :preview-src-list="[item?.url]"
        :src="item?.url" >

      </el-image>

      <div style="position: absolute;bottom:1px;height:40px;width:100%;" class="title" >{{item?.title}}</div>
    </div>

  </div>
</template>

<script>


export default {
    props:{
        imgList:{
            type:Array,
            default:()=>[]
        },

        cardData:{
          type:Object,
          default:()=>{}
        }

    },

    data(){
      return{
        srcList:[],
        srcUrl:""
      }
    },

    methods:{
      /**
       * @Author: yangxin
       * @Date: 2024-08-29 18:11:22
       * @test: test font
       * @msg:
       * @return {*}
       */
      clickImg:function(item){
        this.srcUrl = item.url
        console.log(item)
      }
    },
    computed: {

        /**
         * @Author: YangXin
         * @Description: 从store获取元素
         * @Date: 2024-08-07 18:23:53
         * @return {*}
         */
        // imgList() {
        //   this.imgList.map((item)=>{
        //     this.srcList.push(item.url)
        //   })
        // },

    },
}
</script>



<style lang="scss" scoped>
    .imgStyle {
        margin-bottom:10px;
        height: 33%;
        width: 100%;
        // background-color: red;
    }
    .bigImgStyle{
      position: relative;
        margin-bottom:10px;
        height: 33%;
        width: 100%;
    }
    .imgMain{
        height: 700px;
        width: 100%;
        overflow-y: scroll;

    }

    .title{
      line-height: 40px;
      text-align: center;
      font-size: 22px;
      background-color: rgba(255, 255, 255, 0.1);
      backdrop-filter: blur(2px);
      -webkit-backdrop-filter: blur(2px);
      border: 1px solid rgba(255, 255, 255, 0.18);
      box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;
      -webkit-box-shadow: rgba(142, 142, 142, 0.19) 0px 6px 15px 0px;

      -webkit-border-radius: 5px;
      color: black;

    }

</style>
